import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

import LayoutStyles from '../../../Components/Layout/Styles/LayoutStyles';
import OrganisationContactSuccess from '../../../Components/Organisations/Shared/ContactSuccess';

type Props = {
  organisation: Object,
};

const useStyles = makeStyles(() => ({
  layout: LayoutStyles.centered600Layout,
}));

const QuotationSuccess = ({ organisation }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid className={classes.layout}>
      <OrganisationContactSuccess
        organisation={organisation}
        title={t('APP.QUOTATION.STEP_3.TITLE')}
        subtitle={t('APP.QUOTATION.STEP_3.SUBTITLE')}
      />
    </Grid>
  );
};

export default QuotationSuccess;
